module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/app-icon-prod-512.png","icons":[{"src":"/app-icon-16.png","sizes":"16x16","type":"image/png"},{"src":"/app-icon-32.png","sizes":"32x32","type":"image/png"},{"src":"/app-icon-prod-128.png","sizes":"128x128","type":"image/png"},{"src":"/app-icon-prod-180.png","sizes":"180x180","type":"image/png"},{"src":"/app-icon-prod-512.png","sizes":"512x512","type":"image/png"}],"name":"51bits","short_name":"51bits","start_url":"/","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"93b08d7c66167957f25a68a36f646920"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
